import queryString from "query-string";
import { API_URL } from "../config";
import { rootStore } from "../store";

export const apiRouteUrl = (route: string, query?: any) => {
  let url = `${API_URL}/v1/${route}`;
  if (query) {
    url += `?${queryString.stringify(query)}`;
  }
  return url;
};

const defaultFetchOptions = (method: string) => {
  const options: RequestInit = {
    method,
    headers: {},
  };

  options.headers = {};

  if (method === "POST") {
    options.headers["Content-Type"] = "application/json";
  }

  if (rootStore.activeUserStore.jwt) {
    options.credentials = "include";
    options.headers[
      "Authorization"
    ] = `Bearer ${rootStore.activeUserStore.jwt}`;
  }

  return options;
};

const baseApiRequest = async (url: string, options: any) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    if (response.status === 401) {
      rootStore.activeUserStore.clear();
      //@ts-ignore
      window.location = window.location.protocol + "//" + window.location.host + "/login"
    }
    const errorData: any = await response.json();
    errorData.status = response.status;
    throw errorData;
  }
  if (response.headers.get("Content-Type") === "application/json") {
    return await response.json();
  }
  // This is for 204, for example.
  return undefined;
};

export const getApiRequest = (routeRelativeUrl: string, query?: any) => {
  const url = apiRouteUrl(routeRelativeUrl, query);
  return baseApiRequest(url, defaultFetchOptions("GET"));
};

export const postApiRequest = (routeRelativeUrl: string, body: any) => {
  const options = defaultFetchOptions("POST");
  options.body = JSON.stringify(body);

  const url = apiRouteUrl(routeRelativeUrl);
  return baseApiRequest(url, options);
};

export const deleteApiRequest = (routeRelativeUrl: string) => {
  const url = apiRouteUrl(routeRelativeUrl);
  return baseApiRequest(url, defaultFetchOptions("DELETE"));
};

export const putApiRequest = (routeRelativeUrl: string, body: any) => {
  const options = defaultFetchOptions("PUT");
  options.body = JSON.stringify(body);

  const url = apiRouteUrl(routeRelativeUrl);
  return baseApiRequest(url, options);
};

