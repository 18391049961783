import {getApiRequest, postApiRequest} from "..";
import User from "../../model/User";

export interface UserCredentials {
  email: string;
  password: string;
}

export interface AuthSuccess {
  jwt: string;
  me: User;
}

export const loginApiRoute = async (credentials: UserCredentials) => {
  return (await postApiRequest("login", credentials)) as AuthSuccess;
};

export const retrieveMeApiRoute = async () => {
  return (await getApiRequest(`me`)) as User;
};
