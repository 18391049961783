import { createTheme } from "@mui/material";
import mainTheme from "./mainTheme";

const sidebarTheme = createTheme({
    palette: {
        mode: "dark",
        primary: mainTheme.palette.primary,
    },
    components: {
        MuiList: {
            styleOverrides: {
                root: {
                    position: "relative",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    "& .MuiButtonBase-root.MuiListItem-root, & .MuiButtonBase-root.MuiListItemButton-root":
                        {
                            paddingRight: "40px",
                            [mainTheme.breakpoints.up("md")]: {
                                paddingLeft: "40px",
                            },
                        },
                    "& .MuiListItem-root.active::after": {
                        content: `""`,
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: "4px",
                        background: "#FFA001",
                        zIndex: 10000,
                        borderTopRightRadius: "70px",
                        borderBottomRightRadius: "70px",
                    },
                    "& .MuiListItemIcon-root": {
                        minWidth: 0,
                        marginRight: 16,
                        marginLeft: { xs: "24px", sm: "32px" },
                    },
                },
            },
        },
    },
});

export default createTheme(mainTheme, sidebarTheme);
