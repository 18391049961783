import CardLayout from "./CardLayout";
import {UserStats} from "../../model/User";
import Avatar from '@mui/material/Avatar';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import {Grid, Table, TableBody, TableRow, Typography} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import React from "react";


interface AddressCardProps {
    user: UserStats;
    maxWidth?: string;
}

const AddressCard = ({
                         user,
                         maxWidth
                     }: AddressCardProps) => {
    return (
        <CardLayout
            id={`adr-${user.id}`}
            maxWidth={maxWidth}
            picture={
                <Avatar sx={{width: 100, height: 100}}>
                    <ManageAccountsIcon sx={{width: 64, height: 64}}/>
                </Avatar>
            }
            mainProps={{spaceBetween: true}}
            main={
                <React.Fragment>
                    <Grid item zeroMinWidth maxWidth={"100%"}>
                        <Typography
                            noWrap
                            variant={"h3"}
                            sx={{
                                maxWidth: "100%",
                            }}
                        >
                            Address information
                        </Typography>
                        <Typography variant={"body2"} mt={2}>
                            Brief information about users country, company, industry
                        </Typography>
                        <Grid item sx={{display: {xs: "none", sm: "flex"}}}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Country code:</TableCell>
                                        <TableCell>{user.countryCode}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Country:</TableCell>
                                        <TableCell>{user.country} </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Company:</TableCell>
                                        <TableCell>{user.company}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Industry:</TableCell>
                                        <TableCell>{user.industry}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Profession:</TableCell>
                                        <TableCell>{user.profession}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        />
    )
};

export default AddressCard;