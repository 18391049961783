import {Helmet} from "react-helmet-async";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import {useHistory, useRouteMatch} from "react-router-dom";
import {Typography, Grid, Link, Breadcrumbs, TextField, debounce} from "@mui/material";
import {getUserBalanceApiRoute} from "../../api/routes/users";
import {
    DataGrid,
    GridColumns,
    GridRowsProp,
    gridClasses,
} from "@mui/x-data-grid";
import {UserBalanceItem} from "../../model/User";
import InitialLoading from "../shared/InitialLoading";
import {dashboardDate} from "../../utils/dateFormats";
import {alpha, styled} from '@mui/material/styles';
import CustomFooterTotalComponent from "../shared/CustomFooterTotalComponent";


const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({theme}) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));

interface MatchParams {
    id?: string;
}

const UserBalancePage = () => {

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const userID = useRouteMatch<MatchParams>().params.id;

    const [totalAmount, setTotalAmount] = useState("0");

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [rows, setRows] = useState<GridRowsProp<UserBalanceItem>>([]);
    const [filteredRows, setFilteredRows] = useState<GridRowsProp<UserBalanceItem>>([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("balanceFilter");
        let initialValue = ""
        if (savedFilter) {
            initialValue = JSON.parse(savedFilter);
        }
        return initialValue;
    })

    const loadInitialData = useCallback(async () => {
        try {
            if (userID) {
                const resp = await getUserBalanceApiRoute(userID)
                setRows(resp)
            }
        } catch (error: any) {
            enqueueSnackbar(`Can't get user balance information: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false)
        }
    }, [userID, enqueueSnackbar])

    const columns = useMemo<GridColumns>(() => [
        {
            field: 'balanceDate',
            headerName: 'Balance date',
            flex: 1,
            valueGetter: (params: any) =>
                `${dashboardDate(params.row.balanceDate)} `,
            sortComparator: (a: string, b: string) => {
                return new Date(a) > new Date(b) ? 1 : -1
            },
        }, {
            field: 'externalID',
            headerName: 'External ID',
            flex: 1,
        }, {
            field: 'balanceAmount',
            headerName: 'Balance amount',
            flex: 1,
        }, {
            field: 'balanceCurrency',
            headerName: 'Balance currency',
            flex: 1,
        },
        {
            field: 'sourceType',
            headerName: 'Source type',
            flex: 1,
        },
    ], [])

    useEffect(() => {
        loadInitialData().then();
    }, [loadInitialData]);

    // eslint-disable-next-line
    const getFilteredResults = useCallback(
        debounce((rows: any, filter: string, active: boolean) => {
            if (!active) {
                return;
            }
            let fRows
            if (rows && filter && filter.length > 0) {
                fRows = rows.filter((row: any) => {
                    if (row.externalID && row.externalID.toLowerCase().includes(filter.toLowerCase())) {
                        return true;
                    } else if (row.sourceType && row.sourceType.toLowerCase().includes(filter.toLowerCase())){
                        return true;
                    }
                    return false;
                });
            } else {
                fRows = rows;
            }
            setFilteredRows(fRows);
            let fSum  = fRows.reduce((prev: number, current:any) => {
               return prev + Number(current.balanceAmount)
            },0)
            setTotalAmount(fSum.toFixed(2));
        }, 300),
        []
    );

    useEffect(() => {
        let active = true;
        getFilteredResults(rows, filter, active)

        localStorage.setItem("balanceFilter", JSON.stringify(filter));
        return () => {
            active = false;
        };
    }, [rows, filter, getFilteredResults]);

    return (
        <React.Fragment>
            <Helmet>
                <title>User balance index</title>
            </Helmet>
            <Grid container direction={"column"} spacing={0} width={"100%"}>
                <Grid item mt={7} width={"100%"}>
                    <Typography variant={"h1"}>User balance items</Typography>
                </Grid>
                <Grid container mt={4}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={() => {
                            history.goBack()
                        }} style={{cursor: 'pointer'}}>Back to users</Link>
                        <Typography>User balance items</Typography>
                    </Breadcrumbs>
                </Grid>
                {isLoading ? (
                    <InitialLoading/>
                ) : rows && (
                    <Grid container flexDirection={"column"} mt={1}>
                        <Grid item xs={true}>
                            <TextField
                                sx={{width: "100%"}}
                                id="search"
                                label="Search"
                                helperText={"\u00a0"}
                                value={filter}
                                onChange={(event) => {
                                    setFilter(event.target.value)
                                }}
                                required
                            />
                        </Grid>
                        <div style={{height: '600px', width: '100%'}}>
                            <StripedDataGrid
                                rows={filteredRows}
                                columns={columns}
                                pagination
                                pageSize={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[10, 50, 100]}
                                rowCount={filteredRows.length}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                                onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                                loading={isLoading}
                                components={{
                                    Footer: CustomFooterTotalComponent,
                                }}
                                componentsProps={{
                                    footer: { totalAmount }
                                }}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                            />
                        </div>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
};

export default UserBalancePage;