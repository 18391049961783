import React, { ReactNode, useEffect } from "react";
import { Menu, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type CardMainContainerProps = {
  spaceBetween?: boolean;
};

type CardActionsContainerProps = {
  spaceBetween?: boolean;
};

type CardLayoutProps = {
  id: string;
  maxWidth?: string;
  showActions?: boolean;
  picture: ReactNode;
  main: ReactNode;
  mainProps?: CardMainContainerProps;
  actions?: (inPopupMenu: boolean, closePopup: () => void) => ReactNode;
  actionsProps?: CardActionsContainerProps;
};

const CardLayout = ({
  id,
  maxWidth,
  showActions,
  picture,
  main,
  mainProps,
  actions,
  actionsProps,
}: CardLayoutProps) => {
  const theme = useTheme();
  const canShowVertMenu = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & HTMLButtonElement) | undefined
  >(undefined);

  const closeActionsMobileMenu = () => {
    setAnchorEl(undefined);
  };

  useEffect(() => {
    if (!canShowVertMenu) {
      closeActionsMobileMenu();
    }
  }, [canShowVertMenu]);

  return (
    <Box component={Paper} elevation={1} width={"100%"} maxWidth={maxWidth}>
      <Grid
        container
        direction="row"
        p={{ xs: 2, sm: 4 }}
        flexWrap={"nowrap"}
        justifyContent={"stretch"}
        alignItems={"stretch"}
      >
        <Grid item flexGrow={0} flexShrink={0}>
          {picture}
        </Grid>
        <Grid
          container
          item
          pl={{ xs: 2, sm: 3 }}
          pr={{ xs: 2, sm: 3 }}
          direction={"column"}
          flexGrow={1}
          flexShrink={1}
          flexBasis={"auto"}
          spacing={0}
          alignItems={"flex-start"}
          zeroMinWidth
          width={"100%"}
          height={"auto"}
          sx={{
            justifyContent: {
              xs: "center",
              sm: mainProps?.spaceBetween ? "space-between" : "flex-start",
            },
            "& > .MuiGrid-item": {
              maxWidth: "100%",
            },
          }}
        >
          {main}
        </Grid>
        {showActions && (
          <Grid
            item
            container
            flexGrow={0}
            flexShrink={0}
            flexBasis={{ xs: "24px", sm: "146px", lg: "166px" }}
            pl={{ xs: 0, sm: 2 }}
            pr={{ xs: 0, sm: 1 }}
            sx={{
              borderLeft: { sm: "1px solid #E8E6F8" },
              alignSelf: { xs: "center", sm: "stretch" },
            }}
            flexDirection={"column"}
            justifyContent={"stretch"}
          >
            <Grid
              container
              item
              flexDirection={"column"}
              justifyContent={
                actionsProps?.spaceBetween ? "space-between" : "flex-start"
              }
              height={"100%"}
              sx={{
                display: { xs: "none", sm: "flex" },
              }}
            >
              {actions && actions(false, closeActionsMobileMenu)}
            </Grid>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <IconButton
                aria-label="more"
                id={`long-button-${id}`}
                aria-controls={`long-menu-${id}`}
                aria-expanded={anchorEl ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
                sx={{
                  p: 0,
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`long-menu-${id}`}
                MenuListProps={{
                  "aria-labelledby": `long-button-${id}`,
                }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={closeActionsMobileMenu}
                PaperProps={{
                  elevation: 1,
                  sx: {
                    width: "24ch",
                    pl: 2,
                    pr: 2,
                    pt: 1,
                    pb: 1,
                  },
                }}
              >
                <div>{actions && actions(true, closeActionsMobileMenu)}</div>
              </Menu>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CardLayout;
