import {Helmet} from "react-helmet-async";
import React, {useCallback, useEffect, useState} from "react";
import {Typography, Grid, Box, Paper, Link} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {useSnackbar} from "notistack";
import {getUserStatsApiRoute} from "../../api/routes/users";
import {formatUserName, UserStatsBalance} from "../../model/User";
import InitialLoading from "../shared/InitialLoading";
import {formatPhoneNumber} from "../../utils/phoneNumbers";
import UserAvatar from "../shared/UserAvatar";
import {Breadcrumbs} from "@mui/material";
import {useHistory} from "react-router-dom";
import AddressCard from "../cards/AddressCard";
import BalanceCard from "../cards/BalanceCard";

interface MatchParams {
    id?: string;
}

const UserProfilePage = () => {

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const userID = useRouteMatch<MatchParams>().params.id;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [userStats, setUserStats] = useState<UserStatsBalance | undefined>(
        undefined
    );

    const loadInitialData = useCallback(async () => {
        try {
            if (userID) {
                const resp = await getUserStatsApiRoute(userID)
                setUserStats(resp)
            }
        } catch (error: any) {
            enqueueSnackbar(`Can't get user information: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false)
        }
    }, [userID, enqueueSnackbar])

    useEffect(() => {
        loadInitialData().then();
    }, [loadInitialData]);

    return (
        <React.Fragment>
            <Helmet>
                <title>User profile</title>
            </Helmet>
            <Grid container direction={"column"} spacing={0} width={"100%"}>
                <Grid item mt={7} width={"100%"}>
                    <Typography variant={"h1"}>User profile</Typography>
                </Grid>
                <Grid container mt={4}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={() => {
                            history.goBack()
                        }} style={{cursor: 'pointer'}}>Back to users</Link>
                        <Typography>User details</Typography>
                    </Breadcrumbs>
                </Grid>
                {isLoading ? (
                    <InitialLoading/>
                ) : userStats && (
                    <Grid container width={"100%"} spacing={1} mt={1}
                          alignItems="stretch">
                        <Grid item mt={4} xs={6}>
                            <Grid container direction={"column"}>
                                <Box component={Paper} elevation={1} sx={{maxWidth: "584px"}}>
                                    <Grid container direction="row" p={4} flexWrap={"nowrap"}>
                                        <Grid item flexGrow={0} flexShrink={0}>
                                            <UserAvatar url={userStats.avatarFileName}/>
                                        </Grid>
                                        <Grid item pl={3} pr={3} flexGrow={1} flexShrink={0} xs={12}>
                                            <Grid container direction={"column"} spacing={0}>
                                                <Grid item>
                                                    <Typography variant={"h3"}>{formatUserName(userStats)}</Typography>
                                                </Grid>
                                                <Grid item mt={1}>
                                                    <Typography variant={"body2"}>
                                                        {formatPhoneNumber(userStats.phoneNumber)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant={"body2"}>{userStats.email}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid item mt={4} xs={6}>
                                    <AddressCard user={userStats} maxWidth={"584px"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item mt={4} xs={6}>
                            <BalanceCard user={userStats} maxWidth={"584px"}/>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
};

export default UserProfilePage;