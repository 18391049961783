import React, {useCallback, useEffect, useState} from "react";
import {AppContext} from "../store";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LoggedInLayout from "./layouts/LoggedInLayout";
import ErrorBoundary from "./ErrorBoundary";
import {rootStore} from "../store";
import useRootStore from "../store/useRootStore";
import {observer} from "mobx-react-lite";
import {ThemeProvider} from "@mui/material";
import mainTheme from "./themes/mainTheme";
import CssBaseline from "@mui/material/CssBaseline";
import InitialLoading from "./shared/InitialLoading";

const App = observer(() => {

    const activeUserStore = useRootStore().activeUserStore;

    const [isLoadingMe, setIsLoadingMe] = useState<boolean>(true);

    const loadAboutMe = useCallback(async () => {
        try {
            await activeUserStore.fetch();
        } catch (error) {
            activeUserStore.clear();
        }
        setIsLoadingMe(false);
    }, [activeUserStore]);

    useEffect(() => {
        setTimeout(() => {
            if (activeUserStore.jwt) {
                loadAboutMe().then();
            } else {
                setIsLoadingMe(false);
            }
        }, 300);
    }, [loadAboutMe, activeUserStore.jwt]);


    return (
        <ThemeProvider theme={mainTheme}>
            <CssBaseline/>
            <ErrorBoundary>
                <AppContext.Provider value={rootStore}>
                    {isLoadingMe ? (
                        <InitialLoading/>
                    ) : !activeUserStore.me ? (
                        <Router>
                            <Switch>
                                <Route path="/login" exact component={LoginPage}/>
                                <Redirect from="/" exact to="/login"/>
                            </Switch>
                        </Router>
                    ) : (
                        <Router>
                            <Switch>
                                <Route component={LoggedInLayout}/>
                            </Switch>
                        </Router>
                    )}
                </AppContext.Provider>
            </ErrorBoundary>
        </ThemeProvider>
    );
});

export default App;
