import Payout, {formatPayout} from "../../model/Payout";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, SelectChangeEvent
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSnackbar} from "notistack";
import {updatePayoutsStatusRoute, UpdatePayoutStatus} from "../../api/routes/payoutRoutes";

interface PayoutEditDialogProps {
    isOpen: boolean;
    payout: Payout;
    onClose: () => void;
    onUpdate: (payout: Payout) => void;
}

const PayoutEditDialog = ({isOpen, payout, onClose, onUpdate}: PayoutEditDialogProps) => {

    const {enqueueSnackbar} = useSnackbar();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const [status, setStatus] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
    };

    const updatePayout = async () => {
        try {
            const request: UpdatePayoutStatus = {
                status: status
            }
            let updatedPayout = await updatePayoutsStatusRoute(payout.id, request)
            onUpdate(updatedPayout);
        } catch (error: any) {
            enqueueSnackbar("Can't update payout status", {
                variant: "error",
            });
        } finally {
            setIsUpdating(false);
        }
    }

    useEffect(() => {
        if (payout) {
            setStatus(payout.status);
        }
    }, [payout]);

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Edit {payout && formatPayout(payout)}</DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="payout-status-select-label">Status</InputLabel>
                            <Select
                                labelId={"payout-status-select-label"}
                                id="payout-status-select"
                                value={status}
                                label="Status"
                                onChange={handleChange}
                            >
                                <MenuItem value={"done"}>Done</MenuItem>
                                <MenuItem value={"error"}>Error</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => onClose()}>
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isUpdating}
                    onClick={updatePayout}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
export default PayoutEditDialog;