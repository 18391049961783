import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {EstablishmentStats} from "../../model/Establishment";
import React, {useRef, useState} from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {useSnackbar} from "notistack";
import {CreateEstablishmentAWSApiKeyRequest, createEstablishmentAWSKeysRoute} from "../../api/routes/establishments";
import {validateNonBlankString} from "../../utils/validation";

interface AddAWSApiKeyDialogProps {
    isOpen: boolean;
    establishment: EstablishmentStats;
    onClose: () => void;
}

interface FormErrors {
    awsAPIKey: string | null;
    awsAPIKeyName: string | null;
}

const AddAWSApiKeyDialog = ({isOpen, establishment, onClose }: AddAWSApiKeyDialogProps) => {

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [formErrors, setFormErrors] = useState<FormErrors>({
        awsAPIKey: null,
        awsAPIKeyName: null,
    });

    const apiKeyInput = useRef<HTMLInputElement>(null);
    const apiKeyNameInput = useRef<HTMLInputElement>(null);
    const {enqueueSnackbar} = useSnackbar();

    const close = () => {
        onClose();
    };

    const addAWSApiKey = async () => {
        try {
            const request: CreateEstablishmentAWSApiKeyRequest = {
                apiKey: apiKeyInput.current!.value,
                establishmentID: establishment.id,
                name: apiKeyNameInput.current!.value,
            }
            const formErrors: FormErrors = {
                awsAPIKey: validateNonBlankString(
                    request.apiKey,
                    "Please enter your AWS API key"
                ),
                awsAPIKeyName: validateNonBlankString(
                    request.name,
                    "Please enter your AWS API key name"
                ),
            };
            setFormErrors(formErrors);
            setIsSaving(true);
            await createEstablishmentAWSKeysRoute(establishment.id, request);
            close();
        } catch (error: any) {
            enqueueSnackbar(error.message, {
                variant: "error",
            });
        } finally {
            setIsSaving(false);
        }
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Add AWS API key for "{establishment.name}" </DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={isSaving}
                            sx={{width: "100%"}}
                            id="awsAPIKey"
                            label="AWS API key"
                            error={!!formErrors.awsAPIKey}
                            helperText={formErrors.awsAPIKey || "\u00a0"}
                            inputRef={apiKeyInput}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={isSaving}
                            sx={{width: "100%"}}
                            id="awsAPIKey"
                            label="AWS API key name"
                            error={!!formErrors.awsAPIKeyName}
                            helperText={formErrors.awsAPIKeyName || "\u00a0"}
                            inputRef={apiKeyNameInput}
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => close()}>
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isSaving}
                    onClick={addAWSApiKey}
                >
                    Add Key
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default AddAWSApiKeyDialog;
