import {observer} from "mobx-react-lite";
import Grid from "@mui/material/Grid";
import DashboardPage from "../pages/DashboardPage";
import {Route, Redirect, Switch} from "react-router-dom";
import Box from "@mui/material/Box";
import Sidebar from "../Sidebar";
import PaymentsPage from "../pages/PaymentsPage";
import UserProfilePage from "../pages/UserProfilePage";
import UserBalancePage from  "../pages/UserBalancePage";
import EstablishmentsPage from "../pages/EstablishmentsPage";
import EstablishmentAWSKeyPage from "../pages/EstablishmentAWSKeyPage";
import UserOperationsPage from "../pages/UserOperationsPage";
import PaymentCallbacksPage from "../pages/PaymentCallbacksPage";
import PayoutsPage from "../pages/PayoutsPage";

const LoggedInLayout = observer(() => {

    return (
        <Grid
            container
            item
            direction={"column"}
            flexGrow={1}
            justifyContent="stretch"
            alignItems="stretch"
            spacing={0}
            sx={{
                width: "100%",
                height: "100%",
                bgcolor: "background.default",
                overflow: "hidden"
            }}
        >
            <Grid
                item
                flexGrow={1}
                sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "auto",
                    backgroundPosition: "calc(100vw - 125vh) 0vh",
                    backgroundSize: "220vh",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "none",
                    scrollbarColor: "rgba(163,163,163,255) rgba(0, 0, 0, 0) ",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        position: "relative",
                        zIndex: 2,
                        width: "100%",
                    }}
                >
                    <Sidebar/>
                    <Grid
                        container
                        direction={"column"}
                        pt={3}
                        pb={3}
                        pl={6}
                        pr={6}
                        width={"100%"}
                        overflow={"hidden"}
                    >
                        <Grid
                            item
                            width={"100%"}
                            flexGrow={1}
                            flexShrink={1}
                            overflow={"hidden"}
                        >
                            <Switch>
                                <Route path="/" exact component={DashboardPage}/>
                                <Route path="/payments" exact component={PaymentsPage}/>
                                <Route path="/payments/:id/callbacks" exact component={PaymentCallbacksPage}/>
                                <Route path="/payouts" exact component={PayoutsPage} />
                                <Route path="/users/:id" exact component={UserProfilePage} />
                                <Route path="/users/:id/operations" exact component={UserOperationsPage} />
                                <Route path="/users/:id/balance" exact component={UserBalancePage} />
                                <Route path="/establishments" exact component={EstablishmentsPage} />
                                <Route path="/establishments/:id/aws" exact component={EstablishmentAWSKeyPage}/>
                                <Redirect from="*" to="/not-found"/>
                            </Switch>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
});

export default LoggedInLayout;

