import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet-async";
import Grid from "@mui/material/Grid";
import {debounce, TextField, Tooltip, Typography} from "@mui/material";
import {DataGrid, GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowsProp} from "@mui/x-data-grid";
import {EstablishmentStats} from "../../model/Establishment";
import {useSnackbar} from "notistack";
import {listEstablishmentsApiRoute} from "../../api/routes/establishments";
import EstablishmentLogo from "../shared/EstablishmentLogo";
import Badge from "@mui/material/Badge";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {useHistory} from "react-router-dom";


const RenderHasAWSKey = (props: GridRenderCellParams<EstablishmentStats>) => {

    const hasNoAWSKey = (
        <Tooltip disableFocusListener title={<span>There are no AWS API Key assigned</span>}>
            <HomeIcon/>
        </Tooltip>
    )

    const hasAWSKey = (
        <Tooltip disableFocusListener title={<span>AWS API Key assigned</span>}>
            <Badge color="secondary" variant="dot">
                <HomeIcon/>
            </Badge>
        </Tooltip>
    )

    if (props.row.hasAWSKey) {
        return hasAWSKey
    } else {
        return hasNoAWSKey
    }
}

const RenderEstablishmentLogo = (props: GridRenderCellParams<EstablishmentStats>) => {
    return (
        <EstablishmentLogo size={"small"} url={props.row.logoFileName}/>
    )
}

const EstablishmentsPage = () => {

    const [loadingEstablishments, setLoadingEstablishments] = useState(true);
    const [rows, setRows] = useState<GridRowsProp<EstablishmentStats>>([]);

    const history = useHistory();

    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("establishmentFilter");
        let initialValue = ""
        if (savedFilter) {
            initialValue = JSON.parse(savedFilter);
        }
        return initialValue;
    })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const navigateEstablishmentKeys = useCallback(
        (establishment:EstablishmentStats) => {
            history.push(`/establishments/${establishment.id}/aws`);
        }, [history]
    );

    const columns = useMemo<GridColumns<EstablishmentStats>>(() => [
            {
                field: 'ownerName',
                headerName: 'Owner Name',
                flex: 1,
                valueGetter: (params: any) =>
                    `${params.row.ownerFirstName || ''} ${params.row.ownerLastName || ''}`,
            },
            {
                field: 'ownerEmail',
                headerName: 'Owner Email',
                flex: 1,
                valueGetter: (params: any) =>
                    `${params.row.ownerEmail || ''}`,
            },
            {
                field: 'logoFileName',
                headerName: 'Logo',
                width: 100,
                disableColumnMenu: true,
                disableReorder: true,
                renderCell: RenderEstablishmentLogo,

            },
            {
                field: 'name',
                headerName: 'Establishment Name',
                flex: 1,
            },
            {
                field: 'hasAWSKey',
                headerName: 'API Key',
                flex: 1,
                renderCell: RenderHasAWSKey,
            },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                // @ts-ignore
                <GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Assign AWS API Key"
                    onClick={() => navigateEstablishmentKeys(params.row)}
                    showInMenu={true}
                />,
            ],
        }
        ], [navigateEstablishmentKeys]
    )

    const {enqueueSnackbar} = useSnackbar();

    const [filteredRows, setFilteredRows] = useState<GridRowsProp<EstablishmentStats>>([]);

    // eslint-disable-next-line
    const getFilteredResults = useCallback(
        debounce((rows: any, filter: string, active: boolean) => {
            if (!active) {
                return;
            }
            if (rows && filter && filter.length > 0) {
                setFilteredRows(rows.filter((row: any) => {
                    if (row.ownerEmail && row.ownerEmail.toLowerCase().includes(filter.toLowerCase())) {
                        return true;
                    }
                    if ((row.ownerLastName && row.ownerLastName.toLowerCase().includes(filter.toLowerCase())) || (row.ownerFirstName && row.ownerFirstName.toLowerCase().includes(filter.toLowerCase()))) {
                        return true;
                    }
                    return row.name.toLowerCase().includes(filter.toLowerCase());
                }));
            } else {
                setFilteredRows(rows)
            }
        }, 300),
        []
    );

    const loadEstablishments = useCallback(async () => {
        try {
            const result = await listEstablishmentsApiRoute();
            setRows(result)
        } catch (error: any) {
            enqueueSnackbar(`Can't load establishments: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setLoadingEstablishments(false);
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        loadEstablishments().then();
    }, [loadEstablishments]);

    useEffect(() => {
        let active = true;
        getFilteredResults(rows, filter, active)
        localStorage.setItem("establishmentFilter", JSON.stringify(filter));
        return () => {
            active = false;
        };
    }, [rows, filter, getFilteredResults]);




    return (
        <React.Fragment>
            <Helmet>
                <title>Registered organization establishments</title>
            </Helmet>
            <Grid container direction={"column"} width={"100%"} spacing={0}>
                <Grid item mt={7}>
                    <Typography variant={"h1"}>Registered organization establishments</Typography>
                </Grid>
                <Grid mt={4} container>
                    {loadingEstablishments ? (
                        <Typography variant={"body2"}>Loading establishments...</Typography>
                    ) : (
                        <Grid container flexDirection={"column"}>
                            <Grid item xs={true}>
                                <TextField
                                    sx={{width: "100%"}}
                                    id="search"
                                    label="Search for establishment"
                                    helperText={"\u00a0"}
                                    value={filter}
                                    onChange={(event) => {
                                        setFilter(event.target.value)
                                    }}
                                    required
                                />
                            </Grid>
                            <div style={{height: '700px', width: '100%'}}>
                                <DataGrid
                                    rows={filteredRows}
                                    columns={columns}
                                    pagination
                                    pageSize={rowsPerPage}
                                    page={page}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    rowCount={filteredRows.length}
                                    onPageChange={(newPage) => {
                                        setPage(newPage);
                                    }}
                                    onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
                                    loading={loadingEstablishments}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default EstablishmentsPage;