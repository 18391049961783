import ActiveUserStore from "./ActiveUserStore";

export class RootStore {
  activeUserStore: ActiveUserStore;

  constructor() {
    this.activeUserStore = new ActiveUserStore();
  }

}
