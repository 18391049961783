import React, {useRef, useState} from "react";
import {Typography} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { loginApiRoute } from "../../api/routes/auth";
import useRootStore from "../../store/useRootStore";
import useNotLoggedInLayoutPaper from "../../hooks/useNotLoggedInLayoutPaper";
import NotLoggedInLayout from "../layouts/NotLoggedInLayout";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const LoginPage = () => {

    const activeUserStore = useRootStore().activeUserStore;

    const layout = useNotLoggedInLayoutPaper();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const emailInput = useRef<HTMLInputElement| null>(null);
    const passwordInput = useRef<HTMLInputElement| null>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    const tryToLogin = async () => {

        const credentials = {
            email: emailInput.current!.value,
            password: passwordInput.current!.value,
        };

        setIsLoggingIn(true);

        try {
            const authResult = await loginApiRoute(credentials);
            if (authResult.me?.accountType !== "admin") {
                enqueueSnackbar("Forbidden!", {
                    variant: "error",
                });
                return;
            }
            activeUserStore.storeAuthSuccess(authResult.jwt, authResult.me);
            history.replace("/");
        } catch (error: any) {
            if (error.status === 401) {
                enqueueSnackbar(error.message, {
                    variant: "error",
                })
            } else {
                enqueueSnackbar("Something went wrong...", {
                    variant: "error",
                });
            }
            setIsLoggingIn(false);
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Log In</title>
            </Helmet>
            <NotLoggedInLayout>
                <Paper
                    elevation={layout.elevation}
                    sx={{
                        maxWidth: "100%",
                        width: "656px",
                        ...layout.paddings,
                        ...layout.margins,
                    }}
                >
                    <Grid container direction="column" spacing={1}>
                        <Grid item mt={5}>
                            <Typography textAlign="center" variant="h2">
                                Log In
                            </Typography>
                        </Grid>
                        <Grid item mt={3}>
                            <TextField
                                required
                                fullWidth
                                id="email-input"
                                label="Email"
                                disabled={isLoggingIn}
                                inputRef={emailInput}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter'){
                                        tryToLogin()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                autoComplete="new-password"
                                disabled={isLoggingIn}
                                inputRef={passwordInput}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter'){
                                        tryToLogin()
                                    }
                                }}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <LoadingButton
                                size="large"
                                fullWidth
                                variant="contained"
                                onClick={() => tryToLogin()}
                                loading={isLoggingIn}
                            >
                                Log In
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </NotLoggedInLayout>
        </React.Fragment>
    );
};

export default LoginPage;
