import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {PaymentCallback} from "../../model/Payment";

interface CallbackPayloadDialogProps {
    isOpen: boolean;
    paymentCallback: PaymentCallback;
    onClose: () => void;
}

const CallbackPayloadDialog = ({isOpen, paymentCallback, onClose}: CallbackPayloadDialogProps) => {

    const close = () => {
        onClose();
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Payment callback payload</DialogTitle>
            {paymentCallback && (<DialogContent>
                    <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={true}
                                sx={{width: "100%"}}
                                id="callbackPayload"
                                label="Payload"
                                value={paymentCallback.payload}
                                multiline={true}
                                required
                                rows={7}
                                style={{textAlign: 'left'}}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant={"outlined"} onClick={() => close()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CallbackPayloadDialog;
