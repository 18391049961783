import {getApiRequest, putApiRequest} from "../index";
import Payout from "../../model/Payout";
import {Dayjs} from "dayjs";
import PayoutStatusInfo from "../../model/PayoutStatusInfo";

export const listPayoutsApiRoute = async (userID:string) => {
    return (await getApiRequest(`admin/payouts/${userID}`)) as Payout[];
};

export interface UpdatePayoutStatus {
    status: string;
}

export const updatePayoutsStatusRoute = async (payoutID:string, request: UpdatePayoutStatus) => {
    return (await putApiRequest(`admin/payouts/${payoutID}/status`, request)) as Payout;
};

export const listAllPayoutsApiRoute = async (fromDate:Dayjs, toDate:Dayjs, payoutStatus: string[]) => {
    return (await getApiRequest(`admin/payouts?from=${fromDate.toJSON()}&to=${toDate.toJSON()}&payoutStatus=${payoutStatus.toString()}`)) as PayoutStatusInfo[];
};
