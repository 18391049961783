import {formatUserName, UserStats} from "../../model/User";
import TextField from "@mui/material/TextField";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Checkbox, Tooltip} from "@mui/material";
import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import CountrySelect, {countries, CountryType, getCountry} from "../shared/CountrySelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSnackbar} from "notistack";
import {updateUserApiRoute, UpdateUserStatsRequest} from "../../api/routes/users";
import {validateNonBlankString} from "../../utils/validation";

interface UserEditDialogProps {
    isOpen: boolean;
    user?: UserStats;
    onClose: () => void;
    onUpdate: (user: UserStats) => void;
}

interface FormErrors {
    company: string | null;
    industry: string | null;
    profession: string | null;
    firstName: string | null;
    lastName: string | null;
}


const UserStatsDialog = ({isOpen, user, onClose, onUpdate}: UserEditDialogProps) => {

    const {enqueueSnackbar} = useSnackbar();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    const [countryValue, setCountryValue] = useState<CountryType>(getCountry(user?.countryCode))

    const [firstName, setFirstName] = useState<string>('')

    const [lastName, setLastName] = useState<string>('')

    const [industry, setIndustry] = useState<string>('')

    const [company, setCompany] = useState<string>('')

    const [country, setCountry] = useState<string>(getCountry(user?.countryCode).label)

    const [countryCode, setCountryCode] = useState<string>(getCountry(user?.countryCode).code)

    const [profession, setProfession] = useState<string>('')

    const [isAMLValid, setISAMLValid] = useState<boolean>(false)

    const [isTipsAllowed, setIsTipsAllowed] = useState<boolean>(false)

    useEffect(() => {
        if (user?.industry) {
            setIndustry(user.industry)
        }
        if (user?.countryCode) {
            setCountryValue(getCountry(user?.countryCode))
            setCountryCode(user?.countryCode)
        }
        if (user?.country) {
            setCountry(user?.country)
        }
        if (user?.profession) {
            setProfession(user.profession)
        }
        if (user?.company) {
            setCompany(user.company)
        }
        if (user?.amlCheck) {
            setISAMLValid(user.amlCheck)
        }
        if (user?.firstName) {
            setFirstName(user.firstName)
        }
        if (user?.lastName) {
            setLastName(user.lastName)
        }
        if (user?.tipsCheck) {
            setIsTipsAllowed(user.tipsCheck)
        }
    }, [user]);


    const [formErrors, setFormErrors] = useState<FormErrors>({
        company: null,
        industry: null,
        profession: null,
        firstName: null,
        lastName: null,
    });

    const close = () => {
        setCountry(countries[0].label);
        setCountryCode(countries[0].code);
        setCountryValue(countries[0]);
        setIndustry('');
        setProfession('');
        setCompany('');
        setFirstName('');
        setLastName('');
        setISAMLValid(false);
        onClose();
    };

    const updateUserInfo = async () => {
        try {
            if (onUpdate && user) {
                const request: UpdateUserStatsRequest = {
                    country: country,
                    countryCode: countryCode,
                    company: company,
                    industry: industry,
                    profession: profession,
                    amlCheck: isAMLValid,
                    firstName: firstName,
                    lastName: lastName,
                    tipsCheck: isTipsAllowed,
                }
                const formErrors: FormErrors = {
                    firstName: validateNonBlankString(
                        request.firstName,
                        "Please enter your first name"
                    ),
                    lastName: validateNonBlankString(
                        request.lastName,
                        "Please enter your last name"
                    ),
                    company: null,
                    industry: null,
                    profession: null,

                };
                setFormErrors(formErrors);
                setIsUpdating(true);
                await updateUserApiRoute(user.id, request);
                let newUser = {
                    ...user,
                    industry: industry,
                    company: company,
                    profession: profession,
                    countryCode: countryCode,
                    country: country, amlCheck:
                    isAMLValid,
                    firstName: firstName,
                    lastName: lastName
                };
                onUpdate(newUser);
            }
            close();
        } catch (error: any) {
            enqueueSnackbar(error.message, {
                variant: "error",
            });
        } finally {
            setIsUpdating(false);
        }
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Edit {user && formatUserName(user)}</DialogTitle>
            <DialogContent>
                {user && (
                    <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={isUpdating}
                                sx={{width: "100%"}}
                                id="firstName"
                                label="First name"
                                error={!!formErrors.firstName}
                                helperText={formErrors.firstName || "\u00a0"}
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value)
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={isUpdating}
                                sx={{width: "100%"}}
                                id="lastName"
                                label="Last name"
                                error={!!formErrors.lastName}
                                helperText={formErrors.lastName || "\u00a0"}
                                required
                                value={lastName}
                                onChange={(event) => {
                                    setLastName(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CountrySelect
                                disabled={isUpdating}
                                currValue={countryValue}
                                sx={{width: "100%"}}
                                onFormat={(country, code) => {
                                    setCountry(country);
                                    setCountryCode(code);
                                }}
                                id="countrySelect"
                                label="Country"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={isUpdating}
                                sx={{width: "100%"}}
                                id="industry"
                                label="Company industry"
                                error={!!formErrors.industry}
                                helperText={formErrors.industry || "\u00a0"}
                                value={industry}
                                onChange={(event) => {
                                    setIndustry(event.target.value)
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={isUpdating}
                                sx={{width: "100%"}}
                                id="company"
                                label="Company"
                                error={!!formErrors.company}
                                helperText={formErrors.company || "\u00a0"}
                                required
                                value={company}
                                onChange={(event) => {
                                    setCompany(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled={isUpdating}
                                sx={{width: "100%"}}
                                id="profession"
                                label="Profession"
                                error={!!formErrors.profession}
                                helperText={formErrors.profession || "\u00a0"}
                                required
                                value={profession}
                                onChange={(event) => {
                                    setProfession(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Tooltip disableFocusListener placement={"right-end"} title={
                                <p>If not checked, user can't receive payments</p>}>
                                <FormControlLabel
                                    control={<Checkbox checked={isTipsAllowed} onChange={(event) => {
                                        setIsTipsAllowed(event.target.checked)
                                    }}/>}
                                    label={
                                        <span> User allowed to receive tips </span>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <Tooltip disableFocusListener placement={"right-end"} title={
                                <p>If not checked, user can't create payout requests</p>}>
                                <FormControlLabel
                                    control={<Checkbox checked={isAMLValid} onChange={(event) => {
                                        setISAMLValid(event.target.checked)
                                    }}/>}
                                    label={
                                        <span> User info is valid </span>
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => close()}>
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isUpdating}
                    onClick={updateUserInfo}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
};

export default UserStatsDialog;