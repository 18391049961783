// noinspection TypeScriptValidateTypes

import { createTheme } from "@mui/material";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        inactiveTab: true;
    }
}

let mainTheme = createTheme({});

const accentColor = "#fab733";

mainTheme = createTheme({
    palette: {
        mode: "light",
        text: { primary: "#212121" },
        primary: { main: accentColor },
    },
    typography: {
        fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    },
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: "separate",
                    borderSpacing: "0 16px",
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: 20,
                    "& .MuiMenuItem-root": {
                        "& .MuiSvgIcon-root": {
                            marginRight: 12,
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    mt: 1,
                    border: "none",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    "&:first-of-type": {
                        borderLeft: "1px solid #E8E6F8",
                        borderTopLeftRadius: 25,
                        borderBottomLeftRadius: 25,
                    },
                    "&:last-child": {
                        borderTopRightRadius: 25,
                        borderBottomRightRadius: 25,
                    },
                    borderRight: "1px solid #E8E6F8",
                    borderTop: "1px solid #E8E6F8",
                    borderBottom: "1px solid #E8E6F8",
                    background: "white",
                },
                head: {
                    background: "#F7F7FD",
                    "&:not(:last-child)": {
                        borderRight: "none",
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    border: "none",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: 14,
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 16,
                    },
                },
                body2: {
                    fontSize: 14,
                    color: "#8A8894",
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 16,
                    },
                },
                h1: {
                    fontSize: 32,
                    fontWeight: "bold",
                },
                h2: {
                    fontSize: 18,
                    fontWeight: "bold",
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 24,
                    },
                },
                h3: {
                    fontSize: 18,
                    fontWeight: "bold",
                },
                h4: {
                    fontSize: 16,
                    fontWeight: "bold",
                },
                h5: {
                    fontSize: 14,
                    fontWeight: "bold",
                },
                caption: {
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#FFA001",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#F7F7FD",
                    borderRadius: 25,
                    "& fieldset": {
                        borderColor: "#E8E6F8",
                    },
                    "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                        border: `1px solid ${accentColor}`,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#bebbe0",
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    "& .MuiSwitch-root + .MuiTypography-root": {
                        width: "100%",
                    },
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        marginLeft: 0,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#DFDFDF",
                    border: `5px solid ${accentColor}`,
                },
                rounded: {
                    borderRadius: 10,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    border: "1px solid #E8E6F8",
                },
                elevation0: {
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                },
                elevation1: {
                    boxShadow: "0px 4px 20px rgba(239, 239, 239, 0.75)",
                },
                rounded: {
                    borderRadius: 15,
                },
                outlined: {
                    background: "#F7F7FD",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxSizing: "border-box",
                    boxShadow: "0px 4px 4px rgba(142, 141, 208, 0.1)",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        background: "none",
                        color: accentColor,
                    },
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "inactiveTab" },
                    style: {
                        background: "#F4F3FE",
                        border: "none",
                    },
                },
            ],
            styleOverrides: {
                root: {
                    fontSize: 16,
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    borderRadius: 25,
                    textTransform: "none",
                    boxShadow: "none",
                    ":hover": {
                        boxShadow: "none",
                    },
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 20,
                    },
                },
                contained: {
                    color: "white",
                },
                outlined: {
                    color: "#636363",
                    borderColor: "#636363",
                    "&:hover": {
                        background: "#F6F5FF",
                        borderColor: "#636363",
                    },
                },
                sizeSmall: {
                    fontSize: 14,
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    [mainTheme.breakpoints.up("sm")]: {
                        fontSize: 14,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    fontSize: 16,
                    borderRadius: 25,
                    background: "#F7F7FD",
                    border: "1px solid #E8E6F8",
                    height: "auto",
                    "&:hover": {
                        background: "#F7F7FD",
                        border: "1px solid #bebbe0",
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingLeft: "100px",
                    fontSize: 24,
                    fontWeight: "bold",
                    paddingTop: "64px",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingLeft: "100px",
                    paddingRight: "100px",
                    paddingTop: "70px",
                    paddingBottom: "40px",
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingBottom: "72px",
                    paddingLeft: "100px",
                    paddingRight: "100px",
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    pt: 2,
                    pb: 2,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    "& .MuiTabs-indicator": {
                        height: "5px",
                        borderRadius: "5px",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    borderBottomLeftRadius: "2px",
                    borderBottomRightRadius: "2px",
                    lineHeight: "1.1",
                    paddingBottom: "8px",
                    justifyContent: "flex-end",
                    textTransform: "none",
                    color: "#333333",
                    fontSize: "14px",
                    "&.MuiButtonBase-root.MuiTab-root.Mui-selected": {
                        color: "#333333",
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    fontSize: 14,
                    textAlign: "left",
                    alignSelf: "center",
                    color: "#212121",
                },
                root: {
                    borderRadius: 30,
                    paddingLeft: 32,
                    paddingRight: 32,
                    paddingTop: 8,
                    paddingBottom: 8,
                    justifyContent: "center",
                },
                icon: {
                    fontSize: 30,
                    color: accentColor,
                },
                filledInfo: {
                    border: `1px solid ${accentColor}`,
                    backgroundColor: "#FFF8EC",
                },
                filledSuccess: {
                    backgroundColor: "#FBFFFA",
                },
            },
        },
    },
});

export default mainTheme;
