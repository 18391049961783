import { ReactComponent as LightLogo } from "../../images/itips_logo_light.svg";
import { CSSProperties, SVGProps } from "react";

interface AppLogoProps {
    size?: "normal" | "small" | "tiny";
    marginLeft?: number;
}

const AppLogo = ({ size, marginLeft }: AppLogoProps) => {

    if (!size) {
        size = "normal";
    }

    const svgProps: SVGProps<SVGSVGElement> = {
        role: "img",
    };

    const style: CSSProperties = {
        marginLeft: marginLeft || "0",
        width: size === "normal" ? "232px" : size === "tiny" ? "96px" : "152px",
    };

    return  (
        <LightLogo style={style} {...svgProps} />
    );
};

export default AppLogo;
