import {
    GridFooterContainer,
    GridPagination
} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import {formatCurrencyCodeSign} from "../../model/Payment";
import React from "react";
import PropTypes from "prop-types";

CustomFooterTotalComponent.propTypes = {
    total: PropTypes.number
};

function CustomFooterTotalComponent(props: any) {

    return (
        <GridFooterContainer>
            <Box sx={{padding: "10px", display: "flex"}}>Balance is : {props.totalAmount} {formatCurrencyCodeSign("EUR")}</Box>
            {/*
// @ts-ignore */}
            <GridPagination />
        </GridFooterContainer>
    );
}

export default CustomFooterTotalComponent;