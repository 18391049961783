export type UserAccountType = "individual" | "organization" | "admin";

export default interface User {
    id: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    accountType: UserAccountType;
    avatar?: string;
    didConfirmEmail: boolean;
}

export interface UserStats {
    id: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    didConfirmEmail: boolean;
    paymentsAmount: string;
    payoutsAmount: string;
    accountType: string;
    externalID: string;
    avatarFileName?: string;
    company?: string;
    industry?: string;
    country?: string;
    countryCode?: string;
    profession?: string;
    amlCheck: boolean;
    identityVerificationStatus: string;
    createdAt: string;
    tipsCheck: boolean;
}

export interface EmploymentPaymentTotal {
    employmentID: string,
    establishmentOwnerLastName: string,
    establishmentOwnerFirstName: string,
    establishmentName: string,
    qrCodeName: string,
    amount: string,
    currency: string,
}

export interface ApiPaymentTotal {
    employmentID: string,
    establishmentOwnerLastName: string,
    establishmentOwnerFirstName: string,
    establishmentName: string,
    amount: string,
    currency: string,
}

export interface TransferPaymentTotal extends ApiPaymentTotal {

}

export type UserStatsBalance = UserStats & {
    directPayment: string,
    directPayout: string,
    apiAmount?: string,
    employmentPayments: EmploymentPaymentTotal[],
    apiPayments: ApiPaymentTotal[],
    transferPayments: TransferPaymentTotal[],
}


export const formatUserName = (user: User | UserStats) => {
    return user.firstName + " " + user.lastName;
};

export const formatUserStatsCompany = (user: UserStats) => {
    let result: string = ""
    if (user.countryCode && user.countryCode.length > 0) {
        result += user.countryCode + ", "
    }
    if (user.country && user.country.length > 0) {
        result += user.country + ", "
    }
    if (user.company && user.company.length > 0) {
        result += user.company + ", "
    }
    if (user.industry && user.industry.length > 0) {
        result += user.industry + ", "
    }
    if (user.profession && user.profession.length > 0) {
        result += user.profession
    }
    if (result.length > 0) {
        if (result.slice(-2) === ", ") {
            return result.slice(0, -2);
        }
    }
    return result
};

export const formatUserValidation = (user: UserStats) => {
    let result: string = ""
    if (user.didConfirmEmail) {
        result += "E, ";
    }
    if (user.identityVerificationStatus === "success") {
        result += "SS, ";
    }
    if (user.amlCheck) {
        result += "AML, ";
    }
    if (result.length > 0) {
        if (result.slice(-2) === ", ") {
            return result.slice(0, -2);
        }
    }
    return result
}

export interface UserBalanceItem {
    id: string;
    balanceAmount: string;
    balanceCurrency: string;
    balanceDate: string;
    externalID: string;
    sourceType: string;
    sourceDate: string;
}
