import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {
    getPaymentStatusForAdminApiRoute,
    listPaymentCallbacksAdminApiRoute,
} from "../../api/routes/paymentRoutes";
import {useSnackbar} from "notistack";
import PaymentStatusInfo from "../../model/PaymentStatusInfo";
import {Helmet} from "react-helmet-async";
import {Breadcrumbs, Grid, Link, Menu, MenuItem, Typography} from "@mui/material";
import InitialLoading from "../shared/InitialLoading";
import {formatPaymentStatus, PaymentCallback} from "../../model/Payment";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import {dashboardDate} from "../../utils/dateFormats";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableBody from "@mui/material/TableBody";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CallbackPayloadDialog from "../dialogs/CallbackPayloadDialog";

interface MatchParams {
    id?: string;
}

interface PaymentCallbacksRowProps {
    paymentCallback: PaymentCallback
    onShowPayloadDialog: () => void;
}

const PaymentCallbacksPageRow = ({
                                     paymentCallback,
                                     onShowPayloadDialog,
                                 }: PaymentCallbacksRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={`pcbl-${paymentCallback.id}`}
        >
            <TableCell>{paymentCallback.id}</TableCell>
            <TableCell>{dashboardDate(paymentCallback.doneAt)}</TableCell>
            <TableCell>{paymentCallback.status}</TableCell>
            <TableCell>{paymentCallback.payType}</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-${paymentCallback.id}`}
                        aria-controls={`long-menu-${paymentCallback.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-${paymentCallback.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-${paymentCallback.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onShowPayloadDialog) {
                                    onShowPayloadDialog();
                                }
                                handleClose();
                            }}
                        >
                            <SettingsApplicationsIcon/>
                            Show payload
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
};


const PaymentCallbacksPage = () => {

    const selectedPaymentId = useRouteMatch<MatchParams>().params.id;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusInfo | undefined>(undefined);

    const [paymentCallbacks, setPaymentCallbacks] = useState<PaymentCallback[] | undefined>(undefined);

    const [callbackPayloadDialogIsOpen, setCallbackPayloadDialogIsOpen] = useState<boolean>(false);

    const [selectedPaymentCallback, setSelectedPaymentCallback] = useState<PaymentCallback | undefined>(undefined)

    const handleCallbackPayloadDialogClose = async () => {
        setSelectedPaymentCallback(undefined);
        setCallbackPayloadDialogIsOpen(false);
    }

    const showCallbackPayloadDialog = useCallback(
        (callback: PaymentCallback) => {
            setSelectedPaymentCallback(callback);
            setCallbackPayloadDialogIsOpen(true);
        }, []
    );

    const loadPaymentDetails = useCallback(async () => {
        setIsLoading(true);
        try {
            const payment = await getPaymentStatusForAdminApiRoute(selectedPaymentId!);
            setPaymentStatus(payment);
        } catch (error: any) {
            enqueueSnackbar(`Can't load payment: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false);
        }

    }, [enqueueSnackbar, selectedPaymentId]);

    const loadPaymentCallbacks = useCallback(async () => {
        setIsLoading(true);
        try {
            const callbacks = await listPaymentCallbacksAdminApiRoute(selectedPaymentId!);
            setPaymentCallbacks(callbacks);
        } catch (error: any) {
            enqueueSnackbar(`Can't load payment callbacks: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false);
        }

    }, [enqueueSnackbar, selectedPaymentId]);

    useEffect(() => {
        loadPaymentDetails().then();
        loadPaymentCallbacks().then();
    }, [loadPaymentDetails, loadPaymentCallbacks]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Payment callbacks</title>
            </Helmet>
            <CallbackPayloadDialog
                isOpen={callbackPayloadDialogIsOpen}
                paymentCallback={selectedPaymentCallback!}
                onClose={handleCallbackPayloadDialogClose}
            />
            <Grid container direction={"column"} spacing={0} width={"100%"}>
                <Grid item mt={7} width={"100%"}>
                    <Typography variant={"h1"}>Payment callbacks</Typography>
                </Grid>
                <Grid container mt={4}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={() => {
                            history.goBack();
                        }} style={{cursor: 'pointer'}}>Back to payments</Link>
                        <Typography>Payment callbacks</Typography>
                    </Breadcrumbs>
                </Grid>
                {isLoading ? (
                    <InitialLoading/>
                ) : paymentCallbacks && paymentStatus && (
                    <Grid container flexDirection={"column"} mt={1}>
                        <Typography variant={"body2"}>Payment: {formatPaymentStatus(paymentStatus)}</Typography>
                        <Typography variant={"body2"}>ExternalID: {paymentStatus.externalID}</Typography>
                        <Grid container flexDirection={"column"} spacing={1} pl={2} pr={2} mt={4}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Done at</TableCell>
                                            <TableCell>Pay type</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymentCallbacks.map((paymentCallback) => (
                                            <PaymentCallbacksPageRow paymentCallback={paymentCallback}
                                                                     key={paymentCallback.id}
                                                                     onShowPayloadDialog={() => {
                                                                         showCallbackPayloadDialog(paymentCallback);
                                                                     }}/>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    );
}

export default PaymentCallbacksPage;