import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {StandardTextFieldProps} from "@mui/material/TextField/TextField";

interface CountrySelectProps extends StandardTextFieldProps {
    onFormat?: (value: string, code: string) => void;
    currValue: CountryType;
}

export interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}

export default function CountrySelect(props: CountrySelectProps) {

    const { onFormat, currValue, ...otherProps } = props;

    return (
        <Autocomplete<CountryType>
            id="country-select"
            sx={otherProps.sx}
            disabled={otherProps.disabled}
            value={currValue}
            options={countries}
            onChange={ (e, obj) => {
                if (onFormat && obj ){
                    onFormat(obj.label, obj.code);
                }
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(otherProps, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...otherProps}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choose a country"
                    required={otherProps.required}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

export const countries: readonly CountryType[] = [
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: "BA", label: "Bosnia",  phone: "387" },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'DE', label: 'Germany', phone: '49'},
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FR', label: 'France',  phone: '33' },
    { code: 'HR', label: 'Croatia', phone: '385'},
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'IN', label: 'India',  phone: '91' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
];

export const getCountry = (code: string | undefined) => {
    if (code && code.length > 0) {
        return countries.filter(value => value.code === code)[0];
    } else {
        return countries[0];
    }
}