import {formatUserName, UserStats} from "../../model/User";
import React, {useState} from "react";
import {Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {CreateUserBalanceRequest, postUserBalanceIndexApiRoute} from "../../api/routes/users";
import {useSnackbar} from "notistack";
import Avatar from "@mui/material/Avatar";
import {red} from "@mui/material/colors";

interface BalanceUpdateDialogProps {
    isOpen: boolean;
    user?: UserStats;
    onClose: () => void;
}

const BalanceUpdateDialog = ({isOpen, user, onClose}: BalanceUpdateDialogProps) => {

    const {enqueueSnackbar} = useSnackbar();

    const [isRebuilding, setIsRebuilding] = useState<boolean>(false);

    const rebuildIndex = async () => {
        setIsRebuilding(true)
        try {
            const request: CreateUserBalanceRequest = {
                userID: user!.id
            }
            await postUserBalanceIndexApiRoute(user!.id, request)
            enqueueSnackbar(" Balance index was updated for user", {
                variant: "success",
            });
        } catch (error: any) {
            enqueueSnackbar(error.message, {
                variant: "error",
            });
        } finally {
            setIsRebuilding(false);
            if (onClose) {
                onClose();
            }
        }
    }

    const close = () => {
        onClose();
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} disableRestoreFocus>
            <DialogTitle>Rebuild {user && formatUserName(user)} balance</DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={2} rowSpacing={1} pl={2} pr={2} mt={4}>
                    <Card sx={{ maxWidth: 600 }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                    I
                                </Avatar>
                            }
                            title="Balance index for user will be deleted"
                            subheader="After that, all payments and payouts with done status will be processed"
                        />
                    </Card>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"outlined"} onClick={() => close()}>
                    Cancel
                </Button>
                <LoadingButton
                    variant={"contained"}
                    loading={isRebuilding}
                    onClick={rebuildIndex}
                >
                    Rebuild balance
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default BalanceUpdateDialog;