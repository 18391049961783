import {deleteApiRequest, getApiRequest, postApiRequest} from "../index";
import {EstablishmentAWSApiKey, EstablishmentStats} from "../../model/Establishment";

export const listEstablishmentsApiRoute = async () => {
    return (await getApiRequest("admin/establishments")) as EstablishmentStats[];
};

export const getEstablishmentApiRoute = async (establishmentID:string) => {
    return (await getApiRequest(`admin/establishments/${establishmentID}`)) as EstablishmentStats;
};

export const listEstablishmentAWSKeysRoute = async (establishmentID:string) => {
    return (await getApiRequest(`admin/establishments/${establishmentID}/keys`)) as EstablishmentAWSApiKey[];
}

export interface CreateEstablishmentAWSApiKeyRequest {
    apiKey: string;
    establishmentID: string;
    name: string;
}

export const createEstablishmentAWSKeysRoute = async (
    establishmentID: string,
    request: CreateEstablishmentAWSApiKeyRequest
) => {
    return (await postApiRequest(`admin/establishments/${establishmentID}/keys`, request)) as EstablishmentAWSApiKey;
};

export const deleteEstablishmentAWSKeysRoute = async (establishmentID:string, awsAPIKeyID: string) => {
    return await deleteApiRequest(`admin/establishments/${establishmentID}/keys/${awsAPIKeyID}`);
};

