import {dashboardDate} from "../utils/dateFormats";

export default interface Payout {
    id: string;
    externalID: string;
    amount: string;
    currency : string;
    status: string;
    createdAt: string;
}

export const formatPayout = (pout: Payout) => {
    return `${pout.externalID} ${dashboardDate(pout.createdAt)}`
};
