import {ApiPaymentTotal, EmploymentPaymentTotal, UserStatsBalance} from "../../model/User";
import CardLayout from "./CardLayout";
import Avatar from "@mui/material/Avatar";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import React, {useState} from "react";
import {Collapse, Grid, IconButton, Table, TableBody, TableRow, Typography} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {formatCurrencyCodeSign} from "../../model/Payment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface EmploymentPaymentsQRCodeRowProps {
    totalItem: EmploymentPaymentTotal
}

const EmploymentPaymentsQRCodeRow = ({
                                         totalItem
                                     }: EmploymentPaymentsQRCodeRowProps) => {

    const [openTotal, setOpenTotal] = useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell onClick={() => setOpenTotal(!openTotal)}
                           style={{paddingTop: 0, paddingBottom: 0}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {openTotal ? (
                            <KeyboardArrowUpIcon/>
                        ) : (
                            <KeyboardArrowDownIcon/>
                        )}
                    </IconButton>
                    {totalItem.qrCodeName}:
                </TableCell>
                <TableCell>
                    {totalItem.amount} {formatCurrencyCodeSign(totalItem.currency)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{padding: 0, border: "none"}}
                    colSpan={2}
                >
                    <Collapse
                        in={openTotal}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Table size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Establishment:</TableCell>
                                    <TableCell>{totalItem.establishmentName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Owner:</TableCell>
                                    <TableCell>{totalItem.establishmentOwnerLastName} {totalItem.establishmentOwnerFirstName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>QR-code: </TableCell>
                                    <TableCell>{totalItem.qrCodeName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Amount: </TableCell>
                                    <TableCell>{totalItem.amount} {formatCurrencyCodeSign(totalItem.currency)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

interface EmploymentPaymentsRowProps {
    totalItem: ApiPaymentTotal
}

const EmploymentPaymentsRow = ({
                                         totalItem
                                     }: EmploymentPaymentsRowProps) => {

    const [openTotal, setOpenTotal] = useState(false);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell onClick={() => setOpenTotal(!openTotal)}
                           style={{paddingTop: 0, paddingBottom: 0}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {openTotal ? (
                            <KeyboardArrowUpIcon/>
                        ) : (
                            <KeyboardArrowDownIcon/>
                        )}
                    </IconButton>
                    {totalItem.establishmentName}:
                </TableCell>
                <TableCell>
                    {totalItem.amount} {formatCurrencyCodeSign(totalItem.currency)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{padding: 0, border: "none"}}
                    colSpan={2}
                >
                    <Collapse
                        in={openTotal}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Table size="small" aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Owner:</TableCell>
                                    <TableCell>{totalItem.establishmentOwnerLastName} {totalItem.establishmentOwnerFirstName}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Amount: </TableCell>
                                    <TableCell>{totalItem.amount} {formatCurrencyCodeSign(totalItem.currency)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}


interface BalanceCardProps {
    user: UserStatsBalance;
    maxWidth?: string;
}

const BalanceCard = ({
                         user,
                         maxWidth
                     }: BalanceCardProps) => {
    return (
        <CardLayout
            id={`bal-${user.id}`}
            maxWidth={maxWidth}
            picture={
                <Avatar sx={{width: 100, height: 100}}>
                    <AccountBalanceIcon sx={{width: 64, height: 64}}/>
                </Avatar>
            }
            mainProps={{spaceBetween: true}}
            main={
                <Grid item zeroMinWidth maxWidth={"100%"}>
                    <Typography
                        noWrap
                        variant={"h3"}
                        sx={{
                            maxWidth: "100%",
                        }}
                    >
                        Balance information
                    </Typography>
                    <Typography variant={"body2"} mt={2}>
                        Balance information: income, outcome, balance
                    </Typography>
                    <Grid item sx={{display: {xs: "none", sm: "flex"}}}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Direct payments:</TableCell>
                                    <TableCell>{user.directPayment}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Direct payouts:</TableCell>
                                    <TableCell>{user.directPayout} </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Typography variant={"body2"} mt={2}>
                        Payment information by employment qr-code
                    </Typography>

                    <Table aria-label="simple table">
                        <TableBody>
                            {user.employmentPayments && (user.employmentPayments.length > 0) ? (
                                <React.Fragment>
                                    {user.employmentPayments.map((total) => (
                                        <EmploymentPaymentsQRCodeRow key={`empl-${total.employmentID}`}
                                                                     totalItem={total}/>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <TableRow>
                                    <TableCell>No payments by organization qr-code found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <Typography variant={"body2"} mt={2}>
                        API Payment information by layoutID
                    </Typography>
                    <Table aria-label="simple table">
                        <TableBody>
                            {user.apiPayments && (user.apiPayments.length > 0) ? (
                                <React.Fragment>
                                    {user.apiPayments.map((total) => (
                                        <EmploymentPaymentsRow key={`api-${total.employmentID}`} totalItem={total} />
                                    ))}
                                </React.Fragment>
                            ) : (
                                <TableRow>
                                    <TableCell>No api-payments found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <Typography variant={"body2"} mt={2}>
                        Transfers for the user
                    </Typography>
                    <Table aria-label="simple table">
                        <TableBody>
                            {user.transferPayments && (user.transferPayments.length > 0) ? (
                                <React.Fragment>
                                    {user.transferPayments.map((total) => (
                                        <EmploymentPaymentsRow key={`trf-${total.employmentID}`} totalItem={total} />
                                    ))}
                                </React.Fragment>
                            ) : (
                                <TableRow>
                                    <TableCell>No transfer found</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            }
        />
    )
};

export default BalanceCard;