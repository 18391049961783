import {NavLink, useHistory, useRouteMatch} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import React, {useCallback, useEffect, useState} from "react";
import {Breadcrumbs, Button, Grid, Link, Menu, MenuItem, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {EstablishmentAWSApiKey, EstablishmentStats} from "../../model/Establishment";
import {
    deleteEstablishmentAWSKeysRoute,
    getEstablishmentApiRoute,
    listEstablishmentAWSKeysRoute
} from "../../api/routes/establishments";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import InitialLoading from "../shared/InitialLoading";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyOffIcon from '@mui/icons-material/KeyOff';
import AddIcon from "@mui/icons-material/Add";
import AddAWSApiKeyDialog from "../dialogs/AddAWSApiKeyDialog";
import CloseIcon from '@mui/icons-material/Close';

interface MatchParams {
    id?: string;
}

interface EstablishmentAWSKeyComponentProps {
    apiKeyValue: string;
}

const EstablishmentAWSKeyComponent = ({apiKeyValue}: EstablishmentAWSKeyComponentProps) => {
    const [show, setShow] = useState(false);
    return (
        <>
            {show ?
                (<Typography sx={{ display: "flex", alignItems: "center" }}>{apiKeyValue}<CloseIcon onClick={() => {setShow(false)}}/></Typography>) :
                (<button onClick={() => setShow(prev => !prev)}>Show AWS API Key</button>)
            }
        </>
    );
}

interface EstablishmentAWSKeyTableRowProps {
    apiKey: EstablishmentAWSApiKey
    onChoseToDelete?: () => void;
}

const EstablishmentAWSKeyTableRow = ({
                                         apiKey,
                                         onChoseToDelete,
                                     }: EstablishmentAWSKeyTableRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={apiKey.id}
        >
            <TableCell>{apiKey.name}</TableCell>
            <TableCell>
                <EstablishmentAWSKeyComponent apiKeyValue={apiKey.apiKey}/>
            </TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-${apiKey.id}`}
                        aria-controls={`long-menu-${apiKey.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-${apiKey.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-${apiKey.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onChoseToDelete) {
                                    onChoseToDelete();
                                }
                                handleClose();
                            }}
                        >
                            <KeyOffIcon/>
                            Delete AWS Key
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    )
}


const EstablishmentAWSKeyPage = () => {

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isLoadingEstablishment, setIsLoadingEstablishment] = useState<boolean>(true);

    const establishmentID = useRouteMatch<MatchParams>().params.id;

    const [establishmentStats, setEstablishmentStats] = useState<EstablishmentStats |undefined>(undefined);

    const [apiKeys, setAPIKeys] = useState<EstablishmentAWSApiKey[]>([]);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - apiKeys.length) : 0;

    const [addAWSApiKeyDialogIsOpen, setAWSApiKeyDialogIsOpen] = useState<boolean>(false);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleAddAWSApiKeyDialogClose = async () => {
        loadAWSApiKeys().then()
        setAWSApiKeyDialogIsOpen(false);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const loadEstablishment = useCallback(async () => {
        try {
            if (establishmentID) {
                const resp = await getEstablishmentApiRoute(establishmentID)
                setEstablishmentStats(resp)
            }
        } catch (error: any) {
            enqueueSnackbar(`Can't get establishment: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoadingEstablishment(false)
        }
    }, [establishmentID, enqueueSnackbar])

    const loadAWSApiKeys = useCallback(async () => {
        try {
            if (establishmentID) {
                const resp = await listEstablishmentAWSKeysRoute(establishmentID)
                setAPIKeys(resp)
            }
        } catch (error: any) {
            enqueueSnackbar(`Can't get AWS API Keys: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false)
        }
    }, [establishmentID, enqueueSnackbar])

    const deleteAWSApiKey = useCallback(
        async (establishmentID: string, keyID: string) => {
            try {
                if (window.confirm("Are You sure you want to delete API Key?")) {
                    await deleteEstablishmentAWSKeysRoute(establishmentID, keyID);
                    loadAWSApiKeys().then();
                }
            } catch (error: any) {
                enqueueSnackbar(`Can't delete AWS API Key: ${error.message}`, {
                    variant: "error",
                });
            }
        },
        [enqueueSnackbar, loadAWSApiKeys]
    );

    useEffect(() => {
        loadEstablishment().then();
        loadAWSApiKeys().then();
    }, [loadEstablishment, loadAWSApiKeys]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Establishment AWS API Keys</title>
            </Helmet>
            <Grid container direction={"column"} spacing={0} width={"100%"}>
                <Grid item mt={7} width={"100%"}>
                    <Typography variant={"h1"}>Establishment AWS Keys</Typography>
                </Grid>
                <Grid container mt={4}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={() => {
                            history.goBack()
                        }} style={{cursor: 'pointer'}}>Back to establishments</Link>
                        <Typography>Establishment AWS Keys</Typography>
                        <Typography>{establishmentStats?.name}</Typography>
                    </Breadcrumbs>
                </Grid>
                {isLoading && isLoadingEstablishment ? (
                    <InitialLoading/>
                ) : (
                    <React.Fragment>
                        {establishmentStats &&
                            ( <AddAWSApiKeyDialog
                                isOpen={addAWSApiKeyDialogIsOpen}
                                establishment={establishmentStats!}
                                onClose={handleAddAWSApiKeyDialogClose}/> )
                        }
                        <Grid container direction="column" width={"100%"}>
                            <Grid
                                item
                                container
                                direction={"row"}
                                width={"100%"}
                                mt={4}
                                sx={{
                                    justifyContent: {xs: "center", sm: "end"},
                                    order: {xs: 3, sm: 2},
                                    mt: {xs: 4, sm: 3},
                                }}
                            >
                                <Button
                                    variant={"contained"}
                                    component={NavLink}
                                    to={"#"}
                                    startIcon={<AddIcon/>}
                                    onClick={() => setAWSApiKeyDialogIsOpen(true)}
                                    sx={{
                                        width: {xs: "100%", sm: "auto"},
                                        maxWidth: {xs: "26ch", sm: "auto"},
                                    }}
                                >
                                    Add a AWS API Key
                                </Button>
                            </Grid>
                            <Grid
                                item
                                mt={3}
                                width={"100%"}
                                sx={{
                                    order: {xs: 2, sm: 3},
                                }}
                            >
                                <Grid container flexDirection={"column"} spacing={1} pl={2} pr={2}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ width: '20%' }}>AWS API Key Name</TableCell>
                                                    <TableCell style={{ width: '60%' }}>AWS API Key</TableCell>
                                                    <TableCell style={{ width: '20%' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {apiKeys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((apiKey) => (
                                                    <EstablishmentAWSKeyTableRow key={apiKey.id}
                                                                                 apiKey={apiKey}
                                                                                 onChoseToDelete={() => deleteAWSApiKey(apiKey.establishmentID, apiKey.id)}
                                                    />
                                                ))}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{height: 53 * emptyRows}}>
                                                        <TableCell colSpan={9}/>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={8}
                                        count={apiKeys.length}
                                        component="div"
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default EstablishmentAWSKeyPage