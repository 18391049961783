import {Helmet} from "react-helmet-async";
import React, {useCallback, useEffect, useState} from "react";
import {Breadcrumbs, Grid, Link, Menu, MenuItem, Typography} from "@mui/material";
import {useHistory, useRouteMatch} from "react-router-dom";
import InitialLoading from "../shared/InitialLoading";
import Payment, {formatCurrencyCodeSign} from "../../model/Payment";
import Payout from "../../model/Payout";
import {useSnackbar} from "notistack";
import {listPaymentsApiRoute} from "../../api/routes/paymentRoutes";
import {listPayoutsApiRoute} from "../../api/routes/payoutRoutes";
import {formatUserName, UserStats} from "../../model/User";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import {getUsersApiRoute} from "../../api/routes/users";
import PayoutEditDialog from "../dialogs/PayoutEditDialog";
import {dashboardDate} from "../../utils/dateFormats";
import PaymentEditDialog from "../dialogs/PaymentEditDialog";

interface UserStatsDialogPayoutRowProps {
    payout: Payout
    onPayoutStatusChange?: () => void;
}

const UserStatsDialogPayoutRow = ({
                                      payout,
                                      onPayoutStatusChange,
                                  }: UserStatsDialogPayoutRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={`pout-${payout.id}`}
        >
            <TableCell>{payout.externalID}</TableCell>
            <TableCell>{`${payout.amount} ${payout.currency}`} </TableCell>
            <TableCell>{dashboardDate(payout.createdAt)}</TableCell>
            <TableCell>{payout.status}</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-${payout.id}`}
                        aria-controls={`long-menu-${payout.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-${payout.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-${payout.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onPayoutStatusChange) {
                                    onPayoutStatusChange();
                                }
                                handleClose();
                            }}
                        >
                            <EditIcon/>
                            Change status
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
};

interface UserStatsDialogPaymentRowProps {
    payment: Payment
    onPaymentStatusChange?: () => void;
}

const UserStatsDialogPaymentRow = ({
                                       payment,
                                       onPaymentStatusChange,
                                   }: UserStatsDialogPaymentRowProps) => {

    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | undefined>(undefined);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    return (
        <TableRow
            key={`pay-${payment.id}`}
        >
            <TableCell>{payment.externalID}</TableCell>
            <TableCell>{payment.amount} {formatCurrencyCodeSign(payment.currency)}</TableCell>
            <TableCell>{payment.payType}</TableCell>
            <TableCell>{payment.comment}</TableCell>
            <TableCell>{payment.status}</TableCell>
            <TableCell>{dashboardDate(payment.doneAt)}</TableCell>
            <TableCell>
                <React.Fragment>
                    <IconButton
                        aria-label="more"
                        id={`long-button-pay-${payment.id}`}
                        aria-controls={`long-menu-pay-${payment.id}`}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                        }}
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={`long-menu-pay-${payment.id}`}
                        MenuListProps={{
                            "aria-labelledby": `long-button-pay-${payment.id}`,
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            elevation: 1,
                            style: {
                                width: "20ch",
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                if (onPaymentStatusChange) {
                                    onPaymentStatusChange();
                                }
                                handleClose();
                            }}
                        >
                            <EditIcon/>
                            Change status
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
};

interface MatchParams {
    id?: string;
}

const UserOperationsPage = () => {

    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const selectedUserId = useRouteMatch<MatchParams>().params.id;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [payments, setPayments] = useState<Payment[] | undefined>(undefined);
    const [payouts, setPayouts] = useState<Payout[] | undefined>(undefined);
    const [user, setUser] = useState<UserStats | undefined>(undefined);

    const [selectedPayout, setSelectedPayout] = useState<Payout | undefined>(undefined)
    const [payoutEditDialogIsOpen, setPayoutEditDialogIsOpen] = useState<boolean>(false);

    const [selectedPayment, setSelectedPayment] = useState<Payment | undefined>(undefined)
    const [paymentEditDialogIsOpen, setPaymentEditDialogIsOpen] = useState<boolean>(false);

    const loadUserDetails = useCallback(async () => {
        setIsLoading(true);
        try {
            const [retrievedUser, retrievedPayments, retrievedPayouts] = await Promise.all([
                getUsersApiRoute(selectedUserId!),
                listPaymentsApiRoute(selectedUserId!),
                listPayoutsApiRoute(selectedUserId!),
            ]);
            setUser(retrievedUser);
            setPayments([...retrievedPayments]);
            setPayouts([...retrievedPayouts]);
        } catch (error: any) {
            enqueueSnackbar(`Something went wrong: ${error.message}`, {
                variant: "error",
            });
        } finally {
            setIsLoading(false);
        }

    }, [enqueueSnackbar, selectedUserId]);

    const [paymentsPage, setPaymentsPage] = React.useState(0);
    const [rowsPerPaymentsPage, setRowsPerPaymentsPage] = React.useState(5);
    const handleChangePaymentsPage = (event: unknown, newPage: number) => {
        setPaymentsPage(newPage);
    };
    const handleChangeRowsPerPaymentsPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPaymentsPage(parseInt(event.target.value, 10));
        setPaymentsPage(0);
    };

    const [payoutsPage, setPayoutsPage] = React.useState(0);
    const [rowsPerPayoutsPage, setRowsPerPayoutsPage] = React.useState(5);
    const handleChangePayoutsPage = (event: unknown, newPage: number) => {
        setPayoutsPage(newPage);
    };

    const handleChangeRowsPerPayoutsPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPayoutsPage(parseInt(event.target.value, 10));
        setPayoutsPage(0);
    };

    const showPayoutDialog = useCallback(
        (payout: Payout) => {
            setSelectedPayout(payout);
            setPayoutEditDialogIsOpen(true);
        }, []
    );

    const showPaymentDialog = useCallback(
        (payment: Payment) => {
            setSelectedPayment(payment);
            setPaymentEditDialogIsOpen(true);
        }, []
    );

    const handlePayoutEditDialogClose = async () => {
        setPayoutEditDialogIsOpen(false);
        setSelectedPayout(undefined);
    }

    const handlePayoutUpdate = async (_: Payout) => {
        setPayoutEditDialogIsOpen(false);
        loadUserDetails().then();
    }

    const handlePaymentEditDialogClose = async () => {
        setPaymentEditDialogIsOpen(false);
        setSelectedPayment(undefined);
    }

    const handlePaymentUpdate = async (_: Payment) => {
        setPaymentEditDialogIsOpen(false);
        loadUserDetails().then();
    }

    useEffect(() => {
        loadUserDetails().then();
    }, [loadUserDetails]);

    return (
        <React.Fragment>
            <Helmet>
                <title>User operations</title>
            </Helmet>
            <PaymentEditDialog
                isOpen={paymentEditDialogIsOpen}
                payment={selectedPayment!}
                onClose={handlePaymentEditDialogClose}
                onUpdate={handlePaymentUpdate}
            />
            <PayoutEditDialog
                isOpen={payoutEditDialogIsOpen}
                payout={selectedPayout!}
                onClose={handlePayoutEditDialogClose}
                onUpdate={handlePayoutUpdate}
            />
            <Grid container direction={"column"} spacing={0} width={"100%"}>
                <Grid item mt={7} width={"100%"}>
                    <Typography variant={"h1"}>User operations</Typography>
                </Grid>
                <Grid container mt={4}>
                    <Breadcrumbs>
                        <Link underline="hover" onClick={() => {
                            history.goBack()
                        }} style={{cursor: 'pointer'}}>Back to users</Link>
                        <Typography>User operations</Typography>
                    </Breadcrumbs>
                </Grid>
                {isLoading ? (
                    <InitialLoading/>
                ) : user && (
                    <Grid container flexDirection={"column"} mt={1}>
                        <Typography variant={"body2"}>User: {formatUserName(user)}</Typography>
                        <Typography variant={"body2"}>ExternalID: {user.externalID}</Typography>
                        {payments && payments.length > 0 ? (
                            <Grid container flexDirection={"column"} spacing={1} pl={2} pr={2} mt={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ExternalID</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>PayType</TableCell>
                                                <TableCell>Comment</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>CreatedAt</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {payments.slice(paymentsPage * rowsPerPaymentsPage, paymentsPage * rowsPerPaymentsPage + rowsPerPaymentsPage).map((payment) => (
                                                <UserStatsDialogPaymentRow payment={payment}
                                                                           key={payment.id}
                                                                           onPaymentStatusChange={() => showPaymentDialog(payment)}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={8}
                                    count={payments.length}
                                    component="div"
                                    rowsPerPage={rowsPerPaymentsPage}
                                    page={paymentsPage}
                                    onPageChange={handleChangePaymentsPage}
                                    onRowsPerPageChange={handleChangeRowsPerPaymentsPage}
                                />
                            </Grid>
                        ) : (
                            <Typography variant="body2" mt={4}>
                                There are no payments found for this user.
                            </Typography>
                        )}
                        {payouts && payouts.length > 0 ? (
                            <Grid container flexDirection={"column"} spacing={1} pl={2} pr={2} mt={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ExternalID</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>CreatedAt</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {payouts.slice(payoutsPage * rowsPerPayoutsPage, payoutsPage * rowsPerPayoutsPage + rowsPerPayoutsPage).map((payout) => (
                                                <UserStatsDialogPayoutRow payout={payout}
                                                                          key={payout.id}
                                                                          onPayoutStatusChange={() => showPayoutDialog(payout)}/>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={8}
                                    count={payouts.length}
                                    component="div"
                                    rowsPerPage={rowsPerPayoutsPage}
                                    page={payoutsPage}
                                    onPageChange={handleChangePayoutsPage}
                                    onRowsPerPageChange={handleChangeRowsPerPayoutsPage}
                                />
                            </Grid>
                        ) : (
                            <Typography variant="body2" mt={4}>
                                There are no payouts found for this user.
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        </React.Fragment>
    )
}

export default UserOperationsPage;