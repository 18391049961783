export const isBlankString = (value: string | undefined) => {
    return value === undefined || value === null || value.length === 0;
};


export const validateNonBlankString = (
    value: string | undefined,
    messageWhenBlank: string
) => {
    if (isBlankString(value)) {
        return messageWhenBlank;
    }
    return null;
};