import {getApiRequest, putApiRequest} from "../index";
import Payment, {PaymentCallback} from "../../model/Payment";
import {Dayjs} from "dayjs";
import PaymentStatusInfo from "../../model/PaymentStatusInfo";

export const listPaymentsApiRoute = async (userID:string) => {
    return (await getApiRequest(`admin/payments/${userID}`)) as Payment[];
};

export const listAllPaymentsApiRoute = async (fromDate:Dayjs, toDate:Dayjs, paymentStatus: string[]) => {
    return (await getApiRequest(`admin/payments?from=${fromDate.toJSON()}&to=${toDate.toJSON()}&paymentStatus=${paymentStatus.toString()}`)) as PaymentStatusInfo[];
};

export const getPaymentStatusApiRoute = async (paymentID:string) => {
    return (await getApiRequest(`admin/unknown/payments/${paymentID}/check`) ) as PaymentStatusInfo
}

export interface UpdatePaymentStatus {
    status: string;
    description?: string;
}

export const updatePaymentStatusRoute = async (paymentID:string, request: UpdatePaymentStatus) => {
    return (await putApiRequest(`admin/payments/${paymentID}/status`, request)) as Payment;
};

export const getPaymentStatusForAdminApiRoute = async (paymentID: string) => {
    return (await getApiRequest(`admin/payments/${paymentID}/status`)) as PaymentStatusInfo;
};

export const listPaymentCallbacksAdminApiRoute = async (paymentID: string) => {
    return (await getApiRequest(`admin/payments/${paymentID}/callbacks`)) as PaymentCallback[];
};
